import React, { useState, useEffect } from 'react';
import '../CookieBox.css';
import { useNavigate } from 'react-router-dom';

const CookieBox = () => {
  const [visible, setVisible] = useState(true);

  const [showCookieBox, setShowCookieBox] = useState(true);

    const extractGclidFromURL = () => {
        const currentURL = window.location.href;
        if (currentURL.includes('?')) {
            const queryString = currentURL.split('?')[1];
            const params = new URLSearchParams(queryString);
            return params.get('gclid');
        }
        return null;
    };

    const finalGclid = extractGclidFromURL();

    const handleAcceptCookies = () => {
        if (finalGclid !== null) {
            window.location.href = `/page/?gclid=${finalGclid}`;
        } else {
            window.location.href = "/page/";
        }
    };

    const handleDeclineCookies = () => {
        window.location.href = "/privacy/";
    };

  if (!visible) return null;

  return (
    <div className="cookiebox">
      <style
  dangerouslySetInnerHTML={{ __html: "html, body{\n    overflow: hidden;\n}" }}
/>
      <div className="cookiebox-container">
      <h2><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true">
					<path fill="none" d="M0 0h24v24H0z" />
					<path fill="currentColor" d="M14 9V4H5v16h6.056c.328.417.724.785 1.18 1.085l1.39.915H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8v1h-7zm-2 2h9v5.949c0 .99-.501 1.916-1.336 2.465L16.5 21.498l-3.164-2.084A2.953 2.953 0 0 1 12 16.95V11zm2 5.949c0 .316.162.614.436.795l2.064 1.36 2.064-1.36a.954.954 0 0 0 .436-.795V13h-5v3.949z" />
				</svg> Uporabljamo piškotke</h2>
        <p>Za izboljšanje vaše izkušnje brskanja in analizo prometa na naši strani uporabljamo piškotke. S klikom na "Sprejmem" soglašate z uporabo piškotkov.</p>
        <div className="cookiebox-buttons">
          <button id="btnNo" onClick={handleDeclineCookies} className='button is-ghost' style={{background: "none", color: "black"}}>Zavračam</button>
          <button id="btnYes" onClick={handleAcceptCookies} className='button is-primary'>Sprejmem</button>
        </div>
      </div>
    </div>
  );
};

export default CookieBox;
