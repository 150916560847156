import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CookieBox from '../components/CookieBox';  

const Home = () => (
  <>
    <CookieBox />
    <Header />
    <div
  data-elementor-type="wp-page"
  data-elementor-id={14}
  className="elementor elementor-14"
>
<div
  className="elementor-element elementor-element-4fd35355 e-flex e-con-boxed e-con e-parent e-lazyloaded"
  data-id="4fd35355"
  data-element_type="container"
>
  <div className="e-con-inner">
    <div
      className="elementor-element elementor-element-5b6578d e-con-full e-flex e-con e-child"
      data-id="5b6578d"
      data-element_type="container"
    >
      <div
        className="elementor-element elementor-element-3fbb07eb elementor-widget elementor-widget-text-editor animated fadeIn"
        data-id="3fbb07eb"
        data-element_type="widget"
        data-settings='{"_animation":"fadeIn"}'
        data-widget_type="text-editor.default"
      >
        <div className="elementor-widget-container"></div>
      </div>
      <div
        className="elementor-element elementor-element-56a94433 e-flex e-con-boxed e-con e-child animated fadeIn"
        data-id="56a94433"
        data-element_type="container"
        data-settings='{"animation":"fadeIn","animation_delay":"200"}'
      >
        <div className="e-con-inner">
          <div
            className="elementor-element elementor-element-84cd222 elementor-widget__width-initial elementor-widget elementor-widget-jkit_animated_text"
            data-id="84cd222"
            data-element_type="widget"
            data-widget_type="jkit_animated_text.default"
          >
            <div className="elementor-widget-container">
              <div
                className="jeg-elementor-kit jkit-animated-text jeg_module_14_2_671cf3e467143"
                data-style="rotating"
                data-text="Balaton"
                data-rotate="blinds"
                data-delay={2500}
                data-letter-speed={100}
              >
                <h1 className="animated-text">
                  <span className="normal-text style-color">
                    Prestižni hoteli za nepozabne
                  </span><br />
                  <span
                    className="dynamic-wrapper style-color"
                    style={{ color: "#ffd8ef" }}
                  >
                    trenutke
                  </span>
                  <span className="normal-text style-color" />
                </h1>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-62a4ee00 e-con-full elementor-hidden-mobile elementor-hidden-tablet e-flex e-con e-child"
            data-id="62a4ee00"
            data-element_type="container"
          >
            <div
              className="elementor-element elementor-element-616c9703 elementor-widget__width-initial elementor-hidden-mobile elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
              data-id="616c9703"
              data-element_type="widget"
              data-widget_type="divider.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-divider">
                  <span className="elementor-divider-separator"></span>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-2fbe37fe elementor-widget__width-initial elementor-hidden-mobile elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
              data-id="2fbe37fe"
              data-element_type="widget"
              data-widget_type="divider.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-divider">
                  <span className="elementor-divider-separator"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  className="elementor-element elementor-element-2265ed1f e-flex e-con-boxed e-con e-parent e-lazyloaded"
  data-id="2265ed1f"
  data-element_type="container"
  data-settings='{"background_background":"classic"}'
>
  <div className="e-con-inner">
    <div
      className="elementor-element elementor-element-353d65e5 e-con-full e-flex e-con e-child"
      data-id="353d65e5"
      data-element_type="container"
    >
      <div
        className="elementor-element elementor-element-791b5955 e-con-full e-flex e-con e-child"
        data-id="791b5955"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-7cbdb52d elementor-widget elementor-widget-heading animated fadeIn"
          data-id="7cbdb52d"
          data-element_type="widget"
          data-settings='{"_animation":"fadeIn"}'
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <span>Kje prestiž srečuje eleganco</span>
            <h2 className="elementor-heading-title elementor-size-default">
              O nas
            </h2>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-19510267 elementor-widget elementor-widget-text-editor animated fadeIn"
          data-id={19510267}
          data-element_type="widget"
          data-settings='{"_animation":"fadeIn","_animation_delay":"200"}'
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p>
              Dobrodošli v Top Check Travel - vaša destinacija za odkrivanje
              najluksuznejših hotelov v Sloveniji. Mi smo vaš vodnik po svetu
              sofisticiranosti in vrhunske udobnosti.
            </p>
            <p>
              Naša pozornost je usmerjena v izbor hotelov, ki ne le da
              izpolnjujejo visoke standarde kakovosti in elegance, ampak tudi
              ponujajo edinstveno izkušnjo, ki bo obogatila vaše bivanje.
            </p>
            <p>
              Odkrijte z nami hotele, ki niso le kraji za prenočevanje, ampak
              destinacije, ki ponujajo popolno izkušnjo – od izvrstne kuhinje,
              vrhunskih wellness centrov do ekskluzivnih zabavnih vsebin.
              Top Check Travel - kjer vsak obisk postane nepozaben
              doživetje.
            </p>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-371ad0d7 elementor-widget-mobile__width-inherit elementor-widget elementor-widget-jkit_button animated fadeIn"
        data-id="371ad0d7"
        data-element_type="widget"
        data-settings='{"_animation":"fadeIn","_animation_delay":"600"}'
        data-widget_type="jkit_button.default"
      ></div>
    </div>
    <div
      className="elementor-element elementor-element-9453446 e-con-full e-flex e-con e-child"
      data-id={9453446}
      data-element_type="container"
    >
      <div
        className="elementor-element elementor-element-2ea6bb48 elementor-absolute elementor-widget elementor-widget-jkit_fun_fact animated fadeIn"
        data-id="2ea6bb48"
        data-element_type="widget"
        data-settings='{"_position":"absolute","_animation":"fadeIn","_animation_delay":"200"}'
        data-widget_type="jkit_fun_fact.default"
      >
        <div className="elementor-widget-container">
          <div className="jeg-elementor-kit jkit-fun-fact align-center hover-from-left elementor-animation- jeg_module_14_8_671cf3e474545">
            <div className="fun-fact-inner">
              <div className="content">
                <div className="number-wrapper">
                  <span className="prefix" />
                  <span
                    className="number loaded"
                    data-value={8}
                    data-animation-duration={3500}
                  >
                    8
                  </span>
                  <span className="suffix" />
                  <sup className="super">+</sup>
                </div>
                <h2 className="title">let</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-4edca78b elementor-widget__width-auto elementor-widget elementor-widget-image animated fadeIn"
        data-id="4edca78b"
        data-element_type="widget"
        data-settings='{"_animation":"fadeIn"}'
        data-widget_type="image.default"
      >
        <div className="elementor-widget-container">
          <img
            loading="lazy"
            decoding="async"
            width={2560}
            height={1709}
            src="/files/hotel.webp"
            className="attachment-full size-full wp-image-482"
          />
        </div>
      </div>
    </div>
  </div>
</div>

  <div
    className="elementor-element elementor-element-9a0553c e-con-full e-flex e-con e-parent e-lazyloaded"
    data-id="9a0553c"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
  >
    <div
      className="elementor-element elementor-element-4f52514 elementor-widget elementor-widget-spacer"
      data-id="4f52514"
      data-element_type="widget"
      data-widget_type="spacer.default"
    >
      <div className="elementor-widget-container">
        <div className="elementor-spacer">
          <div className="elementor-spacer-inner" />
        </div>
      </div>
    </div>
  </div>
  <div
    className="elementor-element elementor-element-58c23f85 e-flex e-con-boxed e-con e-parent e-lazyloaded"
    data-id="58c23f85"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-6804b551 e-con-full e-flex e-con e-child"
        data-id="6804b551"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-44519f7a elementor-widget elementor-widget-heading animated fadeIn"
          data-id="44519f7a"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <br />
            <br />
            <br />
            <br />
            <span>Odlične nastanitve za vas z igralnico</span>
<h2 className="elementor-heading-title elementor-size-default">
  Najboljši kazino hoteli
</h2>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-1327bea3 elementor-widget__width-initial elementor-hidden-mobile elementor-widget elementor-widget-text-editor animated fadeIn"
          data-id="1327bea3"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <div
              id="KnM9nf"
              className="PZPZlf"
              data-attrid="tw-sourceArea"
              data-entityname="Google Translate"
            >
              <div id="tw-smenu" className="tw-menu">
              <span>
                  Ne glede na to, ali iščete romantičen pobeg, vikend poln iger in zabave
                  s prijatelji, ali poslovno srečanje s sodelavci, imamo popoln kraj za vas.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-d84519e e-con-full e-flex e-con e-child animated fadeIn"
        data-id="d84519e"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-40d9f05a elementor-widget elementor-widget-jkit_gallery"
          data-id="40d9f05a"
          data-element_type="widget"
          data-widget_type="jkit_gallery.default"
        >
          <div className="elementor-widget-container">
            <div
              className="jeg-elementor-kit jkit-gallery layout-overlay jeg_module_14_45_671cf3e4e56a5"
              data-grid="grid"
              data-id="jeg_module_14_45_671cf3e4e56a5"
              data-per-page={9}
              data-load-more={0}
              data-current-loaded={9}
              data-count-items={9}
              data-animation-duration={500}
              data-no-more="No More Item"
            >
              <div className="gallery-items">
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/cas1.avif"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Perla, Resort & Entertainment
                          </p>
                          <span style={{ fontSize: 18 }}>
                          Kidriceva 7, Nova Gorica, 5000 Slovenia
                          </span>
                          <a
                            href="https://www.expedia.com/Nova-Gorica-Hotels-Perla.h2539148.Hotel-Information"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Rezerviraj zdaj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/cas2.webp"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Exe Lev
                          </p>
                          <span style={{ fontSize: 18 }}>
                          Vosnjakova ulica 1, Ljubljana, 1000 Slovenia
                          </span>
                          <a
                            href="https://www.expedia.com/Ljubljana-Hotels-Hotel-Lev.h46773.Hotel-Information"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Rezerviraj zdaj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/cas3.jpg"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Hotel Maestoso
                          </p>
                          <span style={{ fontSize: 18 }}>
                          Lipica 5, Sezana, 6210 Slovenia<br />
                          </span>
                          <a
                            href="https://www.expedia.com/Sezana-Hotels-Hotel-Maestoso.h1895749.Hotel-Information"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Rezerviraj zdaj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/cas4.webp"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                           Grand Casino Hotel Rogaška
                          </p>
                          <span style={{ fontSize: 18 }}>
                          Zdraviliski Trg 11, Rogaska Slatina, 3250
                          </span>
                          <a
                            href="https://www.expedia.com/Rogaska-Slatina-Hotels-Grand-Hotel-Rogaska.h3091555.Hotel-Information"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Rezerviraj zdaj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/cas5.jpg"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Casino & Hotel ADMIRAL Kozina
                          </p>
                          <span style={{ fontSize: 18 }}>
                          Bazoviška cesta 23, Kozina 6240 Slovenia
                          </span>
                          <a
                            href="https://www.tripadvisor.com/Hotel_Review-g1463475-d1791026-Reviews-Casino_Hotel_ADMIRAL_Kozina-Kozina_Slovenian_Littoral_Region.html"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Rezerviraj zdaj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/cas6.jpg"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Kongo Hotel & Casino
                          </p>
                          <span style={{ fontSize: 18 }}>
                          Ljubljanska cesta 65, Grosuplje, 1290
                          </span>
                          <br />
                          <a
                            href="https://www.expedia.com/Ljubljana-Hotels-Hotel-Kongo.h2536369.Hotel-Information"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Rezerviraj zdaj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="load-more-items" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
</div>


    <Footer />
  </>
);

export default Home;
