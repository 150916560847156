import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../Contact.css';

const Contact = () => {
    const [showThankYou, setShowThankYou] = useState(false);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setShowThankYou(true);
    };

    return (
        <>
            <Header />

            <div
  data-elementor-type="wp-page"
  data-elementor-id={14}
  className="elementor elementor-14"
>
  <div
    className="elementor-element elementor-element-4fd35355 e-flex e-con-boxed e-con e-parent e-lazyloaded"
    data-id="4fd35355"
    data-element_type="container"
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-5b6578d e-con-full e-flex e-con e-child"
        data-id="5b6578d"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-3fbb07eb elementor-widget elementor-widget-text-editor animated fadeIn"
          data-id="3fbb07eb"
          data-element_type="widget"
          data-settings='{"_animation":"fadeIn"}'
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container"></div>
        </div>
        <div
          className="elementor-element elementor-element-56a94433 e-flex e-con-boxed e-con e-child animated fadeIn"
          data-id="56a94433"
          data-element_type="container"
          data-settings='{"animation":"fadeIn","animation_delay":"200"}'
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-84cd222 elementor-widget__width-initial elementor-widget elementor-widget-jkit_animated_text"
              data-id="84cd222"
              data-element_type="widget"
              data-widget_type="jkit_animated_text.default"
            >
              <div className="elementor-widget-container">
                <div
                  className="jeg-elementor-kit jkit-animated-text jeg_module_14_2_671cf3e467143"
                  data-style="rotating"
                  data-text="Balaton"
                  data-rotate="blinds"
                  data-delay={2500}
                  data-letter-speed={100}
                >
                  <h1 className="animated-text">
                    <span className="normal-text style-color">
                      contact
                    </span>
                    <span className="normal-text style-color" />
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-62a4ee00 e-con-full elementor-hidden-mobile elementor-hidden-tablet e-flex e-con e-child"
              data-id="62a4ee00"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-616c9703 elementor-widget__width-initial elementor-hidden-mobile elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                data-id="616c9703"
                data-element_type="widget"
                data-widget_type="divider.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-divider">
                    <span className="elementor-divider-separator"></span>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-2fbe37fe elementor-widget__width-initial elementor-hidden-mobile elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                data-id="2fbe37fe"
                data-element_type="widget"
                data-widget_type="divider.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-divider">
                    <span className="elementor-divider-separator"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="elementor-element elementor-element-2265ed1f e-flex e-con-boxed e-con e-parent e-lazyloaded"
    data-id="2265ed1f"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-353d65e5 e-con-full e-flex e-con e-child"
        data-id="353d65e5"
        data-element_type="container"
      ><br /><br /><br /><br /><br />
                            <form onSubmit={handleFormSubmit}>
                                <label htmlFor="name">Ime:</label>
                                <input type="text" id="name" name="name" required /><br />

                                <label htmlFor="email">Email:</label>
                                <input type="email" id="email" name="email" required /><br />

                                <label htmlFor="message">Sporočilo:</label>
                                <textarea id="message" name="message" rows="4" required></textarea><br />

                                <button type="submit">Pošlji</button>
                            </form>
                            
                            {showThankYou && (
                                <p id="thankYouMessage" className="thank-you">
                                    Hvala, da ste nas contactirali! Odgovorili vam bomo kmalu.
                                </p>
                            )}
      </div>
    </div>
  </div>
  <div
    className="elementor-element elementor-element-9a0553c e-con-full e-flex e-con e-parent e-lazyloaded"
    data-id="9a0553c"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
  >
    <div
      className="elementor-element elementor-element-4f52514 elementor-widget elementor-widget-spacer"
      data-id="4f52514"
      data-element_type="widget"
      data-widget_type="spacer.default"
    >
      <div className="elementor-widget-container">
        <div className="elementor-spacer">
          <div className="elementor-spacer-inner" />
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
</div>
            <Footer />
        </>
    );
};

export default Contact;
