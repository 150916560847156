// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Privacy = () => (
  <>
    <Header />
    <div
      data-elementor-type="wp-page"
      data-elementor-id={14}
      className="elementor elementor-14"
    >
      <div
        className="elementor-element elementor-element-4fd35355 e-flex e-con-boxed e-con e-parent e-lazyloaded"
        data-id="4fd35355"
        data-element_type="container"
      >
        <div className="e-con-inner">
          <div
            className="elementor-element elementor-element-5b6578d e-con-full e-flex e-con e-child"
            data-id="5b6578d"
            data-element_type="container"
          >
            <div
              className="elementor-element elementor-element-3fbb07eb elementor-widget elementor-widget-text-editor animated fadeIn"
              data-id="3fbb07eb"
              data-element_type="widget"
              data-settings='{"_animation":"fadeIn"}'
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container"></div>
            </div>
            <div
              className="elementor-element elementor-element-56a94433 e-flex e-con-boxed e-con e-child animated fadeIn"
              data-id="56a94433"
              data-element_type="container"
              data-settings='{"animation":"fadeIn","animation_delay":"200"}'
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-84cd222 elementor-widget__width-initial elementor-widget elementor-widget-jkit_animated_text"
                  data-id="84cd222"
                  data-element_type="widget"
                  data-widget_type="jkit_animated_text.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="jeg-elementor-kit jkit-animated-text jeg_module_14_2_671cf3e467143"
                      data-style="rotating"
                      data-text="Balaton"
                      data-rotate="blinds"
                      data-delay={2500}
                      data-letter-speed={100}
                    >
                      <h1 className="animated-text">
                        <span className="normal-text style-color">
                          Politika zasebnosti
                        </span>
                        <span className="normal-text style-color" />
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-62a4ee00 e-con-full elementor-hidden-mobile elementor-hidden-tablet e-flex e-con e-child"
                  data-id="62a4ee00"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-616c9703 elementor-widget__width-initial elementor-hidden-mobile elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                    data-id="616c9703"
                    data-element_type="widget"
                    data-widget_type="divider.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-divider">
                        <span className="elementor-divider-separator"></span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-2fbe37fe elementor-widget__width-initial elementor-hidden-mobile elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                    data-id="2fbe37fe"
                    data-element_type="widget"
                    data-widget_type="divider.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-divider">
                        <span className="elementor-divider-separator"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-2265ed1f e-flex e-con-boxed e-con e-parent e-lazyloaded"
        data-id="2265ed1f"
        data-element_type="container"
        data-settings='{"background_background":"classic"}'
      >
        <div className="e-con-inner">
          <div
            className="elementor-element elementor-element-353d65e5 e-con-full e-flex e-con e-child"
            data-id="353d65e5"
            data-element_type="container"
            style={{width: "100%"}}
          ><br /><br /><br /><br /><br />
                             <p>Ta politika zasebnosti opisuje, kako Top Check Travels ("mi", "naš" ali "spletna stran") zbira, uporablja in ščiti vaše osebne podatke, ko uporabljate našo spletno stran <a href="https://topchecktravels.com">topchecktravels.com</a>.</p>

        <h2>1. Zbiranje informacij</h2>
        <p>Osebne podatke lahko zberemo, ko obiščete našo spletno stran, se registrirate, rezervirate storitev ali nas kontaktirate. Zbrani podatki lahko vključujejo vaše ime, e-poštni naslov, telefonsko številko in informacije o potovanjih.</p>

        <h2>2. Uporaba informacij</h2>
        <p>Zbrane informacije uporabljamo za:
            <ul>
                <li>Zagotavljanje in izboljšanje naših storitev.</li>
                <li>Komunikacijo z vami glede rezervacij in uporabniške podpore.</li>
                <li>Pošiljanje informacij o promocijah, ponudbah in posodobitvah naših storitev.</li>
            </ul>
        </p>

        <h2>3. Zaščita podatkov</h2>
        <p>Uvajamo ustrezne tehnične in organizacijske ukrepe za zaščito vaših podatkov pred nepooblaščenim dostopom, spremembami, razkritjem ali uničenjem.</p>

        <h2>4. Deljenje podatkov</h2>
        <p>Vaših podatkov ne delimo s tretjimi osebami, razen v naslednjih primerih:
            <ul>
                <li>Ko je to potrebno za zagotavljanje storitve (npr. sodelovanje s partnerji za rezervacije).</li>
                <li>V skladu z zakonskimi obveznostmi.</li>
            </ul>
        </p>

        <h2>5. Piškotki</h2>
        <p>Naša spletna stran uporablja piškotke za izboljšanje uporabniške izkušnje. Piškotki pomagajo pri analizi obiskov spletne strani in prilagajanju vsebine. Piškotke lahko onemogočite s prilagoditvijo nastavitve brskalnika.</p>

        <h2>6. Pravice uporabnikov</h2>
        <p>Imate pravico zahtevati dostop, popravek, izbris ali omejitev obdelave vaših podatkov. Prav tako imate pravico do ugovora proti obdelavi in prenosljivosti podatkov.</p>

        <h2>7. Spremembe politike zasebnosti</h2>
        <p>Top Check Travels si pridržuje pravico, da občasno posodobi to politiko zasebnosti. Spremembe bodo objavljene na tej strani.</p>

        <p>Če imate kakršna koli vprašanja ali želite dodatne informacije, nas kontaktirajte na e-poštni naslov <a href="mailto:info@topchecktravels.com">info@topchecktravels.com</a>.</p>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-9a0553c e-con-full e-flex e-con e-parent e-lazyloaded"
        data-id="9a0553c"
        data-element_type="container"
        data-settings='{"background_background":"classic"}'
      >
        <div
          className="elementor-element elementor-element-4f52514 elementor-widget elementor-widget-spacer"
          data-id="4f52514"
          data-element_type="widget"
          data-widget_type="spacer.default"
        >
          <div className="elementor-widget-container">
            <div className="elementor-spacer">
              <div className="elementor-spacer-inner" />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>

    <Footer />
  </>
);

export default Privacy;
